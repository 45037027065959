function sortTable(){
    $(document).ready(function() {
        $("#sort").DataTable( {
            "columnDefs": [
                { "orderable": false, "targets": 2 },
                { "orderable": false, "targets": 3 },
                { "orderable": false, "targets": 4 }
              ],
        } );
    } );
 }